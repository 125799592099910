import React from "react";
import { connect } from "react-redux";
import {
  Redux,
  WebClient,
  constants,
  UpDown,
  CFormatDate,
  CButton,
} from "../../components";
import classNames from "classnames";
import Swal from "sweetalert2/src/sweetalert2.js";
import moment from "moment";
import _ from "lodash";
import MatchList from "./live-match-list";
import SocketUtil from "../../components/socket";
import Match from "../../components/models/match";
import timeFilter from "../../components/utils/helpers/timeFilter";

let z = 0;
let l = 0;
let dh = 0;

class LiveMatches extends React.Component {
  constructor(props) {
    super(props);
    this.webClient = new WebClient();
    this.socket = undefined;
    this.state = {
      loading: true,
      gameUrl: "",
      start: new Date(),
      end: moment(new Date(), "DD-MM-YYYY").add(45, "days"),
      activeSport: { sportId: 1, sportName: "Football" },
      schema: [],
      listReady: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.startAction();
    }, 2000);
  }

  async startAction() {
    let a = await localStorage.getItem("userId");

    if (a != null) {
      Redux.setMemberData({ userId: a });
      this._getMatches(this.state.activeSport);
    }
  }

  _getMatches(e, isc = true) {
    if (isc) {
      this.setState({ matches: [], listReady: false, activeSport: e });
    }

    Redux.setLiveActiveSport(e.sportId);

    let obj = window?.livesportsnew ?? window?.livesports;
    let z = obj[1];
    let odds = z?.settings ? JSON.parse(z.settings) : [];

    const typenames = Redux.getTypeNames(e.sportId);
    const selected_tabs = Redux.getSelectedLiveTabsForSecondScreen(e.sportId);

    const start = new Date();
    const end = moment(new Date(), "DD-MM-YYYY").add(6, "hours");

    let d = {
      startDate: moment(start).format("YYYY-MM-DD HH:mm"),
      endDate: moment(end).format("YYYY-MM-DD HH:mm"),
      sportId: e.sportId,
      odds,
      limit: 200,
      sportCountLive: 1,
    };

    this.webClient
      .post(
        constants.uris.live,
        d,
        {
          setLang: true,
          setOperatorId: true,
        },
        false
      )
      .then((response) => {
        if (response.status) {
          dh = this.matchListDiv.clientHeight;

          if (isc) {
            let first = response.matches[0];
            let z = response.matches.filter((x) => x.betstatus == "started");

            if (z.length > 0) {
              z = _.orderBy(z, ["matchtime"], ["asc"]);
              first = z[0];
            }

            const first_odds = first?.odds ?? [];
            this._generateTitleSchema(
              odds[0].odds,
              typenames,
              first_odds,
              selected_tabs,
              response.matches
            );
          }

          let _m = [];
          if (response.matches.length > 0) {
            response.matches.forEach((m) => {
              if (timeFilter(m)) return;
              _m.push(new Match(m));
            });
          }

          Redux.setMid(null);
          Redux.setLiveMatches(_m);
          this.setState({
            sports: response.sports,
            typenames: typenames,
            ready: true,
            listReady: true,
            totalRows: response.totalRows,
          });

          if (isc) {
            this.socket = new SocketUtil();
            this.socket.init();
            this._setSocketInterval();
          }

          this._setLiveMatchTimer();
        }
      });
  }

  _setSocketInterval() {
    this.socketInterval = setInterval(() => {
      this.socket.reset();
    }, 300000);

    // Add socket data listener
    this.socket.on('data', (data) => {
      console.log('Received socket data:', data);
      this.handleSocketData(data);
    });
  }

  handleSocketData(data) {
    // Process the socket data and update matches
    const updatedMatches = this.props.live_matches.map(match => {
      const updatedMatchData = data.find(m => m.matchId === match.id);
      if (updatedMatchData) {
        return match.update(updatedMatchData);
      }
      return match;
    });

    Redux.setLiveMatches(updatedMatches);
    this.forceUpdate(); // Force a re-render to reflect the updates
  }

  _setLiveMatchTimer() {
    if (this.interval !== undefined) clearInterval(this.interval);
    this.interval = setInterval(() => {
      this._getMatchScheduled(this.state.activeSport.sportId);
    }, 90000);
  }

  _getMatchScheduled(sportId) {
    let odds = Redux.getLiveOddsBySportId(sportId);
  
    const start = new Date();
    const end = moment(new Date(), "DD-MM-YYYY").add(6, "hours");
    let lm = this.props.live_matches || []; // Ensure lm is an array
  
    let d = {
      startDate: moment(start).format("YYYY-MM-DD HH:mm"),
      endDate: moment(end).format("YYYY-MM-DD HH:mm"),
      sportId: sportId,
      odds,
      limit: 200,
      sportCountLive: 1,
    };
  
    this.webClient
      .post(
        constants.uris.live,
        d,
        {
          setLang: true,
          setOperatorId: true,
        },
        false
      )
      .then((response) => {
        if (response.status) {
          console.log('Received updated match data:', response.matches);
          let m = response.matches;
  
          // Remove matches that are not in the new response
          lm = lm.filter(e => m.some(x => x.matchId == e.id));
  
          // Add or update matches based on the new response
          m.forEach(e => {
            const existingMatch = lm.find(x => x.id == e.matchId);
            if (existingMatch) {
              existingMatch.check(e);
            } else {
              lm.push(new Match(e));
              Redux.setStatusChanged(e.matchId);
            }
          });
  
          Redux.setLiveMatches(lm);
          this.setState({
            sports: response.sports,
            totalRows: response.totalRows,
          });
        }
      });
  }

  _generateTitleSchema(odds, typenames, match_odds, selected_tabs, matches) {
    let schema = [];
    let markets = [];

    z = 1;
    l = 1;
    schema.push({ y: "d", o: [], order: 0 });
    schema.push({ y: "t", o: [], order: 0 });

    odds.map((el) => {
      let odd_type = undefined;

      for (let q = 0; q < matches.length; q++) {
        let qz = matches[q];
        let qq = qz?.odds?.filter((x) => x.oddstype == el.oid);
        if (qq.length > 0) {
          odd_type = qq[0];
          break;
        }
      }

      if (odd_type !== undefined) {
        let has_special = false;

        matches.map((e) => {
          e.odds
            .filter((x) => x.oddstype == el.oid)
            .map((f) => {
              if (!f?.special?.startsWith("*")) has_special = true;
            });
        });

        let first_half = [];

        let has_first_half = el?.ht ? true : false;
        let is_first_half = Boolean(
          odds.filter((x) => x.ht == el.oid)?.length > 0
        );

        if (!is_first_half) {
          if (has_first_half) {
            let first_half_odds = undefined;
            for (let q = 0; q < matches.length; q++) {
              let qq = matches[q].odds?.filter((x) => x.oddstype == el.ht);
              if (qq.length > 0) {
                first_half_odds = qq[0];
                break;
              }
            }

            if (first_half_odds) {
              if (has_special) {
                first_half.push({
                  o: ".s",
                  i: first_half_odds.odds[0].outComeId,
                  t: el.ht,
                });
              }

              first_half_odds.odds.map((se) => {
                first_half.push({
                  o: se.outCome
                    .replace(" ({0})", "")
                    .replace(" ({h})", "")
                    .replace("{sw}", ""),
                  i: se.outComeId,
                  t: el.ht,
                });
              });
            }
          }

          let o = [];

          if (has_special) {
            o.push({
              o: ".s",
              i: odd_type.odds[0].outComeId,
              t: odd_type.odds[0].oddsType,
            });
          }

          odd_type.odds.map((se) => {
            o.push({
              o: se.outCome
                .replace(" ({0})", "")
                .replace(" ({h})", "")
                .replace("{sw}", ""),
              i: se.outComeId,
              t: el.oid,
            });
          });

          markets.push({
            y: "o",
            t: odd_type.typeName.replace("{sw}", ""),
            o,
            s: has_special,
            i: el.oid,
            fh: first_half,
            hf: has_first_half,
            ics: false,
          });
        }
      }
    });

    let column = 0;
    let filtered = markets.filter((x) => selected_tabs.includes(x.i));

    filtered.map((f) => {
      let _o =
        selected_tabs.indexOf(f.i) == -1
          ? 1000
          : selected_tabs.indexOf(f.i) * 10;

      f.ics = true;
      f.order = _o;
      schema.push(f);
      column++;
      l++;
      z = z + f.o.length;
    });

    filtered = markets.filter((x) => !selected_tabs.includes(x.i));

    for (let i = 0; i < filtered.length; i++) {
      let _z = Boolean(column < 5);
      let _o =
        selected_tabs.indexOf(filtered[i].i) == -1
          ? 1000
          : selected_tabs.indexOf(filtered[i].i) * 10;
      filtered[i].ics = _z ? true : false;
      filtered[i].order = _o;
      schema.push(filtered[i]);
      if (_z) {
        l++;
        column++;
        z = z + filtered[i].o.length;
      }
    }

    schema = _.orderBy(schema, ["order"], ["asc"]);

    let stabs = schema
      .filter((x) => x.y == "o" && x.ics == true)
      .reduce((a, o) => (a.push(o.i), a), []);
    Redux.updateSelectedLiveTabsForSecondScreen(
      this.state.activeSport.sportId,
      stabs
    );

    this.setState({ schema });
  }

  _getHalfTime() {
    if (this.state.activeSport.sportId == 1) {
      return 45;
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.socketInterval) {
      clearInterval(this.socketInterval);
    }
  }

  render() {
    const { loading, gameUrl, schema, activeSport, listReady, typenames } =
      this.state;

    return (
      <div className="bg-cgray-600" style={{ width: "100vw", height: "100vh" }}>
        <div className="match-title-cont">
          {schema.map((s, sindex) => {
            if (s.y == "o" && !s.ics) return null;

            return (
              <div
                key={sindex}
                data-box={z}
                data-col={l}
                className={`t-${s.y} s-t-o-${s.o?.length ?? 0}`}
              >
                {s.y == "d" && <p></p>}

                {s.y == "t" && <p>{activeSport?.sportName}</p>}

                {s.y == "o" && s.ics && (
                  <div className="relative w-full">
                    <span className="text-center w-full">
                      {s.t?.replace("{pw}", "").split("(")[0]}
                    </span>
                    <div className="t-o-c">
                      {s.o.map((o, oindex) => {
                        return (
                          <div className="t-o-i" key={oindex}>
                            {o.o == ".s" ? "" : o.o}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {s.y == "s" && <div></div>}

                {s.y == "a" && <div></div>}
              </div>
            );
          })}
        </div>

        <div
          ref={(ref) => {
            this.matchListDiv = ref;
          }}
          className="slive-in-page-middle-right"
        >
          {listReady && (
            <MatchList
              typenames={typenames}
              schema={schema}
              dh={dh}
              z={z}
              l={l}
              activeSport={activeSport}
              halfTime={this._getHalfTime()}
              onDetail={(e) => {}}
              onShowStatistics={(e) => {}}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    live_matches: state.live_matches,
  };
};

export default connect(mapStateToProps)(LiveMatches);