import Axios from "axios";
import constants from "./constants_epics";
import { store } from "../redux";
import { Redux } from "..";
import io from "socket.io-client";

const defaultPrefs = {
  setUserId: true,
  setReqId: true,
  setMachineId: true,
  setLang: true,
  setOperatorId: true,
  setToken: false,
};

let socket;
let isConnecting = false;
let reconnectTimer = null;
let lastDataTimestamp = Date.now();
let connectionCheckInterval = null;
const RECONNECT_INTERVAL = 1500; // 2 seconds
const SOCKET_TIMEOUT = 35000; // 35 seconds
const CONNECTION_CHECK_INTERVAL = 5000; // 5 seconds

function initializeSocket() {
  console.log('Socket açma isteği');
  if (socket?.connected) {
    console.log('Socket zaten bağlı, yeni bağlantı açılmıyor');
    return;
  }
  if (isConnecting) {
    console.log('Bağlantı zaten devam ediyor, yeni istek atlanıyor');
    return;
  }

  isConnecting = true;
  console.log('Socket açma isteği ilerledi');
  
  // Eğer önceki bir socket varsa, onu temizleyelim
  if (socket) {
    socket.close();
    socket.removeAllListeners();
  }
  
  socket = io("wss://insckt.ibaterm.com", {
    transports: ["websocket"],
    autoConnect: false,
    reconnection: false, // We'll handle reconnection manually
  });

  socket.on("connect", () => {
    console.log("Socket bağlandı");
    isConnecting = false;
    clearTimeout(reconnectTimer);
    updateLastDataTimestamp();
  });

  socket.on("data", (data) => {
    updateLastDataTimestamp();
    console.log(data);
    if (!data) {
      console.log("Boş veya tanımsız veri alındı");
      return;
    }
    if (data.msg?.type === "moneyup" && data.msg.terminalId == localStorage.getItem("TerminalId")) {
      getUserData();
    }
  });

  socket.on('disconnect', () => {
    console.log('Socket bağlantısı kesildi');
    reconnectSocket();
  });

  socket.on('error', (error) => {
    console.error('Socket hatası:', error);
    reconnectSocket();
  });

  socket.open();

  // Start the connection check interval
  startConnectionCheck();
}

function updateLastDataTimestamp() {
  lastDataTimestamp = Date.now();
  console.log('Son veri alım zamanı güncellendi:', new Date(lastDataTimestamp).toISOString());
}

function startConnectionCheck() {
  if (connectionCheckInterval) {
    clearInterval(connectionCheckInterval);
  }
  connectionCheckInterval = setInterval(() => {
  //  const messageee= socket.emit('heartbeat', { terminal: localStorage.getItem("TerminalId") });
  //  if(messageee.connected)
  //  {
  //   updateLastDataTimestamp();
  //  }
    const timeSinceLastData = Date.now() - lastDataTimestamp;
    console.log('Son veri alımından bu yana geçen süre:', timeSinceLastData, 'ms');
    if (timeSinceLastData > SOCKET_TIMEOUT && !isConnecting) {
      console.log('Son 35 saniyedir veri alınmadı, yeniden bağlanılıyor...');
      reconnectSocket();
    }
  }, CONNECTION_CHECK_INTERVAL);
}

function reconnectSocket() {
  if (isConnecting) {
    console.log('Zaten yeniden bağlanma işlemi devam ediyor, atlanıyor');
    return;
  }

  isConnecting = true;
  
  if (socket) {
    console.log('Mevcut socket bağlantısı kapatılıyor');
    socket.close();
    socket.removeAllListeners();
    socket = null;
    isConnecting = false;  }

  clearTimeout(reconnectTimer);

  reconnectTimer = setTimeout(() => {
    console.log('Yeniden bağlanma deneniyor...');
    initializeSocket();
  }, RECONNECT_INTERVAL);
}

function emitTerminalMessage(action, data = {}) {
  const message = {
    msg: {
      terminalId: localStorage.getItem("TerminalId"),
      type: action,
      ...data
    }
  };
  socket.emit('terminal', message);
}

function emitTerminalMessageremote(action, data = {}) {
  let message = {
    msg: {
      terminalId: localStorage.getItem("TerminalId"),
      type: action,
      ...data
    }
  };
  socket.emit('terminal', message);
}

class WebClient {
  constructor() {
    this.axiosInstance = Axios.create({
      baseURL: constants.base_url,
      headers: { "Content-Type": "application/json" },
    });
  }

  get = (path) => this.createProxyPromise(this.axiosInstance.get(path));

  post = (path, data, prefs = defaultPrefs, showLoading = true) => {
    const states = store.getState();
    const d = this.preparePostData(data, prefs, states);
    return this.createProxyPromise(this.axiosInstance.post(path, d), showLoading);
  };

  preparePostData(data, prefs, states) {
    const d = { ...data, site_id: states.settings.site_id };
    if (prefs.setLang) d.lang = 'tr';
    if (prefs.setMachineId) d.machineId = localStorage.getItem("TerminalId");
    if (prefs.setReqId) d.reqId = constants.reqId;
    if (prefs.setUserId) d.userId = states.member?.userId || "0";
    if (prefs.setOperatorId) d.operatorId = states.settings.operatorId;
    if (prefs.setToken) d.token = Math.random().toString(36).substring(2);
    if (prefs.setTokenWithUser) d.token = states.member.userId;
    if (prefs.setServerId) d.serverId = states.settings.serverid;
    return d;
  }

  createProxyPromise(realPromise, showLoading = true) {
    if (showLoading) Redux.showLoading();
    return realPromise
      .then(this.responseHandler)
      .catch(this.errorHandler)
      .finally(() => Redux.hideLoading());
  }

  responseHandler = (response) => response.data;

  errorHandler = (error) => {
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 401:
          console.log("Oturum süreniz doldu veya sonlandı! Lütfen tekrar giriş yapmayı deneyin.");
          break;
        case 405:
          console.log("İstek metodu hatalı!");
          break;
        case 403:
          console.log(error.response.data.message);
          break;
        default:
          console.log("Beklenmeyen bir hata oluştu:", error.response.status, error.response.data);
      }
    } else {
      console.log("Bir hata oluştu:", error.message);
    }
    throw error;
  };
}

function getUserData(type = 1, uid = null) {
  const userId = type === 2 ? uid : store.getState().member.userId;
  localStorage.setItem("userId", userId);

  const wc = new WebClient();
  wc.post(constants.uris.data, { userId }, { setReqId: true, setLang: true })
    .then((response) => {
      if (response.status) {
        Redux.setMemberData({ userId, ...response });
        if (response?.terminalNo) localStorage.setItem("TerminalId", response.terminalNo);
        if (type === 2) getBingoUrl();
      } else {
        if (response.status === false && response.logout === true) {
          emitTerminalMessage("logout");
        }
        Redux.setReady(true);
        Redux.showToast("warning", response.error);
      }
    })
    .catch((error) => {
      Redux.setReady(true);
      Redux.showToast("error", "Bir hata oluştu");
    });
}

function getBingoUrl() {
  const wc = new WebClient();
  wc.post(
    constants.uris.bngo,
    {
      token: Math.random().toString(36).substr(2),
    },
    {
      setReqId: true,
      setMachineId: false,
      setUserId: true,
      setLang: true,
    }
  ).then((response) => {
    if (!response.status) {
      return;
    }
    const config = store.getState().config;

    const video = config.settings.secondScreen === "TombalaVideo" ? 0 : 1;

    const gameUrl = `${response.content.gameurl}&terminal=1&video=${video}&sendData=1&origin=${window.location.origin}`;

    Redux.setBingoUrl(gameUrl);

    if (config.settings.secondScreen === "TombalaVideo") {
      const a = response?.content?.gameurl?.split("?token");
      if (a?.length > 0) {
        const gameUrlForSecondScreen = `${a[0]}/#/secondScreen?token${a[1]}`;
        localStorage.setItem("bingoUrl", gameUrlForSecondScreen);
      }
    }

    if (window?.electronAPI?.ipcRenderer) {
      window.electronAPI.ipcRenderer.send("loginSucceeded", "_");
    }
  });
}

function GetSettings(onSuccess = () => {}, onError = () => {}) {
  const cc = new WebClient();
  cc.get("https://data.apiepics.com/v1/settings/get?domain=pino24.com")
    .then((response) => {
      if (response?.generalJs) {
        const script = document.createElement("script");
        script.src = "/settings/getsettingslocal.js";
        script.async = true;
        document.body.appendChild(script);
      }
      const site_interval = setInterval(() => {
        if (!window?.site_id) return;
        clearInterval(site_interval);
        constants.base_url = `https://api.ibaterm.com`;
        Redux.setSettings({ ...response, site_id: window?.site_id || null, operatorId: response.operatorId });
        onSuccess();
        SetConnectionListener();
      }, 100);
    })
    .catch((error) => {
      Redux.showToast("error", "Ayarlar alınamadı. Lütfen daha sonra tekrar deneyin.");
      onError();
      SetConnectionListener();
    });
}

function LangChanger(lang = 'en') {
  const wc = new WebClient();
  wc.post(
    constants.uris.lngcnhg,
    {},
    {
      setReqId: true,
      setLang: true,
      setUserId: true,
    }
  )
    .then((response) => {
      if (response.status) {
        console.log("Dil başarıyla değiştirildi");
      } else {
        Redux.setReady(true);
        Redux.showToast("warning", response.error);
      }
    })
    .catch((error) => {
      Redux.setReady(true);
      Redux.showToast("error", "Dil değiştirirken bir hata oluştu");
    });
}

function SetConnectionListener() {
  window.addEventListener("online", onlineStatusChange);
  window.addEventListener("offline", onlineStatusChange);
}

function onlineStatusChange() {
  const a = store.getState().settings;
  Redux.setConnectionError(!navigator.onLine);
  if (navigator.onLine && a === null) {
    GetSettings();
  }

  if (navigator.onLine) {
    window.electronAPI.ipcRenderer.send("OpenCoinService", "_");
  } else {
    window.electronAPI.ipcRenderer.send("CloseCoinService", "_");
  }
}

function tF(val) {
  const w = window?.settings?.toFixedNumber ?? 2;
  return parseFloat(val).toFixed(parseFloat(val) > 1.001 && parseFloat(val) < 1.009 ? 3 : w);
}

// Initialize the socket connection
initializeSocket();

export { WebClient, constants, getUserData, GetSettings, tF, emitTerminalMessage, emitTerminalMessageremote, LangChanger, getBingoUrl };