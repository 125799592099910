import React from "react";
import { injectIntl } from "react-intl";
import { FlatList } from "react-native";
import { connect } from "react-redux";
import { Redux, WebClient, constants } from "../../../components";
import assets from "../../../components/utils/assets";
import CButton from "./components/button";
import CButtonLP from "./components/long-press-button";
import moment from "moment";
import MatchList from "./components/match-list";
import SearchOverlay from "./components/search-overlay";
import CStatisticsIframe from "./components/statistics";
import CMatchDetail from "./components/live-match-detail";
import classNames from "classnames";
import EmptyTitle from "./components/empty-title";
import _ from "lodash";
import Match from "../../../components/models/match";

import Coupon from "./coupon";

let limit = 75;
let tournament_title = null;
let scrollM = 0;
let scrollV = 0;
let pages = 1;
let currentPage = 1;
let bottomReached = false;

let z = 0;
let l = 0;
let dh = 0;

class Matches extends React.Component {
  constructor(props) {
    super(props);
    this.wc = new WebClient();
    this.matchListDiv = React.createRef();
    this.state = {
      list: [0, 0, 0, 0, 0, 0],
      activeSport: null,
      activeCategory: { id: -1 },
      tournaments: [],
       multipleobj: [],
      activeTournament: null,
      sports: [],
      categories: [],
      timeSelection: 0,
      daySelection: -1,
      start: new Date(),
      end: moment(new Date(), "DD-MM-YYYY").add(15, "days"),
      rawList: [],
      matches: [],
      postData: {},
      dayList: [],
      keyword: "",
      showSearchOverlay: false,
      showStatistics: false,
      statisticsUrl: "",
      matchDetail: [],
      showMatchDetail: false,
      selectedMatch: null,
      schema: [],
      ready: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this._setDayList();
    this._getSports();


    // this._getMatches(
    //   1,
    //   4,1
    // );
  }

  _setDayList() {
    let a = [];
    let b = new Date();
    for (let i = 1; i < 7; i++) {
      a.push(moment(b, "DD-MM-YYYY").add(i, "days"));
    }
    this.setState({ dayList: a });
  }

  _getSports() {
    const { start, end } = this.state;

    this.wc
      .post(
        constants.uris.sprt,
        {
          startDate: moment(start).format("YYYY-MM-DD HH:mm"),
          endDate: moment(end).format("YYYY-MM-DD HH:mm"),
        },
        {
          setLang: true,
          setOperatorId: true,
        }
      )
      .then((response) => {
        // console.log(response)

        if (response.status) {
          let activeSport = null;
          if (response.results?.length > 0) {
            activeSport = response.results[0];
            this._getCategories(activeSport);
          }

          this.setState({ sports: response.results, activeSport });
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  _getCategories(sport) {
    this.setState({ activeSport: sport });

    const { start, end } = this.state;

    this.wc
      .post(
        constants.uris.ctgr,
        {
          startDate: moment(start).format("YYYY-MM-DD HH:mm"),
          endDate: moment(end).format("YYYY-MM-DD HH:mm"),
          sportId: sport.id,
        },
        {
          setLang: true,
          setOperatorId: true,
        }
      )
      .then((response) => {
        if (response.status) {
          let tm = response.results.reduce(
            (a, b) => a + (b.matchCount || 0),
            0
          );
          let b = [
            {
              title: this.props.intl.formatMessage({ id: "select_all" }),
              id: -1,
              matchCount: tm,
              flagSee: 1,
              countryFlag: assets.select_all,
            },
            ...response.results,
          ];
          let c = b[0];

          this.setState({
            categories: b,
            activeCategory: c,
          });

          this._getMatches(
            this.state.activeSport.id,
            c.id == -1 ? undefined : c.id
          );
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  _getTournaments(category) {
    this.setState({ activeCategory: category, tournaments: [] });

    const { start, end, activeSport } = this.state;

    this.wc
      .post(
        constants.uris.tour,
        {
          startDate: moment(start).format("YYYY-MM-DD HH:mm"),
          endDate: moment(end).format("YYYY-MM-DD HH:mm"),
          categoryId: category.id,
          sportId: activeSport.id,
        },
        {
          setLang: true,
          setOperatorId: true,
        }
      )
      .then((response) => {
        // console.log(response)
        if (response.status) {
          let res = response.results;
          this.setState({ tournaments: res });
          this._getMatches(
            activeSport.id,
            category.id == -1 ? undefined : category.id
          );
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  _getMatches(
    id,
    categoryId = undefined,
    tournamentId = undefined,
    keyWord = undefined,
    multipleleaguew2 =[]
  ) {
    console.log('calistim')
    this.setState({ matches: [], isLoading: true, ready: false });

    let odds = Redux.getNormalOddsBySportId(id);

    const { start, end, activeCategory } = this.state;

    let p = Math.ceil(activeCategory.matchCount / limit);
    pages = p;
let multipleLeaguew ={
  
      
          "categoryId": 1,
          "leagues": [2222, 3042]
      
  
};


    let d = {
      startDate: moment(start).format("YYYY-MM-DD HH:mm"),
      endDate: moment(end).format("YYYY-MM-DD HH:mm"),
      sportId: id,
     //categoryId,
      //tournamentId,
      multipleLeague:  this.state.multipleobj,
      keyWord:'',
      odds,
      limit: activeCategory.matchCount > 150 ? 150 : activeCategory.matchCount,
    };

    this.wc
      .post(
        constants.uris.mtch,
        d,
        {
          setLang: true,
          setOperatorId: true,
        },
        false
      )
      .then((response) => {
        if (response.status) {
          // console.log(response.results)
          dh = this.matchListDiv.clientHeight;
          let r = response.results;

          let _m = [];
          if (r.length > 0) {
            r.map((m) => {
              _m.push(new Match(m));
            });
          }

          let tourns = _m.reduce(function (rv, x) {
            (rv[x["tid"]] = rv[x["tid"]] || []).push(x);
            return rv;
          }, {});

          let _final = [];
          Object.keys(tourns).forEach((e) => {
            let _a = _.orderBy(tourns[e], ["tio"], ["desc"]);
            let _af = _a[0];

            _final.push({
              schema: true,
              title: _af.tn,
              cid: _af.cid,
              tid: _af.tid,
            });

            _a.map((__a) => _final.push(__a));
          });

          this._generateTitleSchema(odds, r);


          this.setState({ matches: _final, postData: d, isLoading: false, ready: true });
        }

        this.setState({ isLoading: false, ready: true });
      })
      .catch((err) => {
        console.log(err)
        this.setState({ isLoading: false, ready: true });
      });
  }
  isActive(rowId) {
    for (let item of this.state.multipleobj) {
      if (item.leagues.includes(rowId)) {
        return true;
      }
    }
    return false;
  }
  _generateTitleSchema(odds, matches) {
    let schema = [];

    z = 2;
    l = 2;
    schema.push({ y: "d", o: [], order: 0 });
    schema.push({ y: "t", o: [], order: 0 });

    odds.map((el) => {
      let odd_type = undefined;

      for (let q = 0; q < matches.length; q++) {
        let qz = matches[q];
        let qq = qz?.odds?.filter((x) => x.oddstype == el.oddsType);
        if (qq.length > 0) {
          odd_type = qq[0];
          break;
        }
      }

      if (odd_type !== undefined) {
        let has_special = false;

        matches.map((e) => {
          e.odds
            .filter((x) => x.oddstype == el.oddsType)
            .map((f) => {
              if (f.specialvalue != "") has_special = true;
            });
        });

        let o = [];
        if (has_special) {
          o.push({
            o: ".s",
            i: odd_type.odds[0].outComeId,
            t: odd_type.odds[0].oddsType,
          });
          z++;
        }

        odd_type.odds.map((se) => {
          o.push({
            o: se.outCome
              .replace(" ({0})", "")
              .replace(" ({h})", "")
              .replace("{sw}", ""),
            i: se.outComeId,
            t: el.oddsType,
          });
          z++;
        });
        schema.push({
          y: "o",
          t: odd_type.typeName.replace("{sw}", ""),
          o,
          s: has_special,
          i: el.oid,
          fh: null,
          hf: false,
          ics: false,
        });
      }
    });

    schema.push({ y: "s", o: [], order: 10000 });
    schema.push({ y: "a", o: [], order: 10000 });

    // console.log(schema)

    this.setState({ schema: schema });
  }

  _getMatchesNextPage() {
    let d = this.state.postData;
    d.limit = limit * currentPage;

    this.wc
      .post(
        constants.uris.mtch,
        d,
        {
          setLang: true,
          setOperatorId: true,
        },
        false
      )
      .then((response) => {
        if (response.status) {
          let r = response.results;
          let _m = [];
          if (r.length > 0) {
            r.map((m) => {
              _m.push(new Match(m));
            });
          }
          this.setState({ matches: _m, postData: d });
        }

        bottomReached = false;
      });
  }

  _getTournamentMatches(tournament) {
    const { activeSport, activeCategory } = this.state;
    this.setState({ activeTournament: tournament }, () => {
        this.addOrUpdateLeague(activeSport, activeCategory, tournament);
    });
}

addOrUpdateLeague(activeSport, activeCategory, tournament) {
    this.state.multipleobj = this.state.multipleobj || [];
  const existingCategoryIndex =  this.state.multipleobj.findIndex(item => item.categoryId === activeCategory.id);

  if (existingCategoryIndex !== -1) {
      const leagueIndex =  this.state.multipleobj[existingCategoryIndex].leagues.indexOf(tournament.id);
      if (leagueIndex !== -1) {
          // Turnuva zaten varsa çıkar
          this.state.multipleobj[existingCategoryIndex].leagues.splice(leagueIndex, 1);
          if ( this.state.multipleobj[existingCategoryIndex].leagues.length < 1)
            {
              this.state.multipleobj.splice(existingCategoryIndex, 1); 
            }
      } else {
          // Turnuva yoksa ekle
          this.state.multipleobj[existingCategoryIndex].leagues.push(tournament.id);
      }
  } else {
      const newLeague = {
          categoryId: activeCategory.id,
          leagues: [tournament.id]
      };
      this.state.multipleobj.push(newLeague);
  }
let sonarray = this.state.multipleobj;
  this.setState({ sonarray }, () => {
      this._getMatches(activeSport.id, activeCategory.id, tournament.id);
  });
}

  _leftArrow() {
    if (scrollM > 0) {
      scrollM = scrollM - 1;
      this.l.scrollToIndex({ animated: true, index: "" + scrollM });
    } else {
      scrollM = this.state.sports.length - 1;
      this.l.scrollToIndex({ animated: false, index: "" + scrollM });
    }
  }

  _rightArrow() {
    if (scrollM < this.state.sports.length - 1) {
      scrollM = scrollM + 1;
      this.l.scrollToIndex({ animated: true, index: "" + scrollM });
    } else {
      scrollM = 0;
      this.l.scrollToIndex({ animated: false, index: "" + scrollM });
    }
  }

  _getToday() {
    var start = new Date();
    start.setHours(0, 0, 0, 0);

    var end = new Date();
    end.setHours(23, 59, 59, 999);

    // console.log(start, end)

    this.setState(
      { timeSelection: 2, daySelection: -1, start, end, keyword: "" },
      () => {
        this._getSports();
      }
    );
  }

  _get6Hours() {
    let start = new Date();
    let end = moment(start, "DD-MM-YYYY").add(6, "hours");
    this.setState(
      { timeSelection: 1, daySelection: -1, start, end, keyword: "" },
      () => {
        this._getSports();
      }
    );
  }

  _getAll() {
    let start = new Date();
    let end = moment(start, "DD-MM-YYYY").add(45, "days");
    this.setState(
      { timeSelection: 0, daySelection: -1, start, end, keyword: "" },
      () => {
        this._getSports();
      }
    );
  }

  _getByDay(st, index) {
    let start = st.toDate();
    start.setHours(0, 0, 0, 0);
    var end = moment(start, "DD-MM-YYYY").add(1, "days");
    // console.log(start, end)

    this.setState(
      { daySelection: index, timeSelection: -1, start, end, keyword: "" },
      () => {
        this._getSports();
      }
    );
  }

  _search() {
    const { activeSport, keyword } = this.state;
    this._getMatches(activeSport.id, undefined, undefined, keyword);
  }

  _getMatchDetail(e) {
    this.wc
      .post(
        constants.uris.odds,
        {
          matchId: e.id,
          getFull: 1,
        },
        {
          setLang: true,
          setOperatorId: true,
          setUserId: true,
        }
      )
      .then((response) => {
        // console.log(response)

        if (response.status) {
          this.setState({
            selectedMatch: e,
            matchDetail: response.results,
            showMatchDetail: true,
          });
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  _listOnScroll() {
    // console.log("currentPage", currentPage)
    if (pages > 1 && currentPage < pages) {
      let a = this.matchListDiv.scrollHeight;
      let b = this.matchListDiv.scrollTop;
      let c = this.matchListDiv.clientHeight;
      let d = a - b;
      if (d <= c * 2 && !bottomReached) {
        // console.log("bottom")
        bottomReached = true;
        // this._getNextPage()
        currentPage = currentPage + 1;
        this._getMatchesNextPage();
      }
    }
  }

  _getNextPage() {
    currentPage = currentPage + 1;
    this._getMatchesNextPage();
  }

  render() {
    const { intl } = this.props;
    const {
      sports,
      activeSport,
      categories,
      activeCategory,
      timeSelection,
      daySelection,
      tournaments,
      activeTournament,
      matches,
      dayList,
      keyword,
      showSearchOverlay,
      showStatistics,
      statisticsUrl,
      matchDetail,
      showMatchDetail,
      selectedMatch,
      ready,
      schema,
      isLoading,
    } = this.state;
    const width = window.innerWidth;

    const showStaticCoupon = Boolean(width > 1300);

    return (
      <div
        className={classNames({
          "in-page-cont": !showStaticCoupon,
          "in-page-cont-2": showStaticCoupon,
        })}
      >
        <div className={classNames({ "in-page-layout-1": showStaticCoupon })}>
          <div className="in-page-top">
            <div className="in-page-top-col1">
              <CButton
                uppercase="uppercase"
                translate={true}
                width="w-1/3"
                text="lang_all"
                active={Boolean(timeSelection == 0)}
                onClick={() => {
                  this._getAll();
                }}
              />
              <CButton
                uppercase="uppercase"
                translate={true}
                width="w-1/3"
                text="lang_6_hour"
                active={Boolean(timeSelection == 1)}
                onClick={() => {
                  this._get6Hours();
                }}
              />
              <CButton
                uppercase="uppercase"
                translate={true}
                width="w-1/3"
                text="lang_today"
                active={Boolean(timeSelection == 2)}
                onClick={() => {
                  this._getToday();
                }}
              />
            </div>

            <div
              className={classNames({
                "in-page-top-col2": !showStaticCoupon,
                "in-page-top-col2-2": showStaticCoupon,
              })}
            >
              {sports.length > 3 && (
                <CButton
                  width="w-1/12"
                  butid="firstarrow"
                  icon={true}
                  iconName="fa-chevron-left"
                  onClick={() => {
                    this._leftArrow();
                  }}
                />
              )}

              <div
                className={classNames({
                  "in-page-top-col2-mid": !showStaticCoupon,
                  "in-page-top-col2-mid-2": showStaticCoupon,
                })}
              >
                <FlatList
                  ref={(ref) => {
                    this.l = ref;
                  }}
                  data={sports}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  horizontal={true}
                  renderItem={({ item, index }) => {
                    return (
                      <CButton
                        key={index}
                        width={showStaticCoupon ? "w-spe2" : "w-spe"}
                        uppercase="uppercase"
                        isSport={true}
                        sportId={item.id}
                        active={Boolean(activeSport?.id === item.id)}
                        text={`${item.title} (${item.matchCount})`}
                        onClick={() => {
                          this._getCategories(item);
                        }}
                      />
                    );
                  }}
                />
              </div>

              {sports.length > 3 && (
                <CButton
                  width="w-1/12"
                  icon={true}
                  iconName="fa-chevron-right"
                  butid="lastarrow"
                  onClick={() => {
                    this._rightArrow();
                  }}
                />
              )}
            </div>
            {!showStaticCoupon && (
              <div className="in-page-top-col3">
                <CButton
                  uppercase="uppercase"
                  translate={true}
                  text="lang_coupon"
                  onClick={() => {
                    this.props.onCouponOpen();
                  }}
                />
              </div>
            )}
          </div>

          <div className="in-page-middle">
            <div className="in-page-middle-left">
              <div className="search-cont">
              <input
  onClick={() => {
    this.setState({ showSearchOverlay: true, keyword: "" });
  }}
  type="text"
  value={keyword}
  onChange={(e) => this.setState({ keyword: e.target.value })}
  placeholder={intl.formatMessage({ id: "lang_match_search" })}
/>
                {keyword.length == 0 ? (
                  <i className="fas fa-search" />
                ) : (
                  <button
                    onClick={() => {
                      this._getAll();
                    }}
                    className="erase"
                  >
                    <i className="fas fa-times" />
                  </button>
                )}
              </div>

              <div
                ref={(ref) => (this.categoryList = ref)}
                className="category-cont hide-scrollbar"
              >
                {categories.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className="c-button-cont"
                        style={{ height: 45, marginBottom: 1 }}
                      >
                        <button
                          className="c-button-type2"
                          onClick={() => {
                            this._getTournaments(item);
                          }}
                      
                        >
                          <div className="img-c">
                            <img
                              src={
                                index == 0
                                  ? item.countryFlag
                                  : `https:${item.countryFlag.replace("bflags","flags")}`
                              }
                              style={{ width: "50%", height: "auto" }}
                            />
                          </div>

                          {item.title}

                          <span className="count">{item.matchCount}</span>
                        </button>
                      </div>
                      {/* <CButton 
                      key={index} 
                      type={2} 
                      flagSee={item.flagSee} 
                      countryFlag={index == 0 ? item.countryFlag : `https:${item.countryFlag}`} 
                      active={Boolean(item.id === activeCategory.id)} 
                      text={item.title} 
                      count={item.matchCount} 
                      onClick={() => { this._getTournaments(item) }} 
                    /> */}
                      {activeCategory.id != -1 &&
                        activeCategory.id == item.id &&
                        tournaments.map((row, yindex) => {
                          // let active = Boolean(activeTournament?.id == row.id);
                          let active2 = this.isActive(row.id);
                          return (
                            <div
                              key={row.id}
                              className="c-button-cont font-alumni"
                              style={{ height: 45, marginBottom: 4 }}
                            >
                              <button
                               className={`c-button-type3 ${
                                active2 ? "active" : ""
                              }`}
                                onClick={() => {
                                  this._getTournamentMatches(row);
                                }}
                              >
                                <div className="img-c">
                                  <i className="fas fa-chevron-right" />
                                </div>

                                <div className="c-button-type3-title">
                                  {row.title}
                                </div>

                                <span className="count">{row.matchCount}</span>
                              </button>
                            </div>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div className="in-page-middle-right-cont">
              <EmptyTitle schema={schema} z={z} l={l} />
              <div
                ref={(ref) => (this.matchListDiv = ref)}
                className="in-page-middle-right"
              >
              {isLoading ? (
              <div className="loading-indicator">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            ) : ready && (
              <MatchList
                z={z}
                l={l}
                dh={dh}
                schema={schema}
                matches={matches}
                activeSport={activeSport}
                onDetail={(e) => {
                  this._getMatchDetail(e);
                }}
                onShowStatistics={(e) => {
                  this.setState({ showStatistics: true, statisticsUrl: e });
                }}
              />
            )}
          </div>
        </div>
          </div>

          <div className="in-page-bottom">
            <div className="in-page-bottom-col1">
              <CButtonLP
                width="w-1/2"
                icon={true}
                iconName="fa-chevron-up"
                onClick={() => {
                  this.categoryList.scrollTo({
                    top: this.categoryList.scrollTop - 60,
                  });
                }}
              />

              <CButtonLP
                width="w-1/2"
                icon={true}
                iconName="fa-chevron-down"
                onClick={() => {
                  // this.categoryList.scrollToOffset({ animated: true, offset: scrollV + 200 })
                  this.categoryList.scrollTo({
                    top: this.categoryList.scrollTop + 60,
                  });
                }}
              />
            </div>

            <div className="in-page-bottom-col2">
              {dayList.map((item, index) => {
                return (
                  <CButton
                    key={index}
                    width="w-125"
                    uppercase="uppercase"
                    active={Boolean(daySelection == index)}
                    text={moment(item).format("dddd")}
                    onClick={() => {
                      this._getByDay(item, index);
                    }}
                  />
                );
              })}

              <CButtonLP
                width="w-125"
                icon={true}
                iconName="fa-chevron-up"
                onClick={() => {
                  this.matchListDiv.scrollTo({
                    top: this.matchListDiv.scrollTop - 60,
                  });
                }}
              />

              <CButtonLP
                width="w-125"
                icon={true}
                iconName="fa-chevron-down"
                onClick={() => {
                  this.matchListDiv.scrollTo({
                    top: this.matchListDiv.scrollTop + 60,
                  });
                }}
              />
            </div>
          </div>
        </div>

        {showStaticCoupon && (
          <div className="static-coupon-container">
            <Coupon onClose={() => {}} hideClose={true} />
          </div>
        )}

        {showSearchOverlay && (
          <SearchOverlay
            keyword={keyword}
            onChange={(e) => {
              this.setState({ keyword: e });
            }}
            onClose={() => {
              this.setState({ showSearchOverlay: false, keyword: "" });
            }}
            onSearch={() => {
              this.setState({ showSearchOverlay: false }, () => {
                this._search();
              });
            }}
          />
        )}

        {showStatistics && (
          <CStatisticsIframe
            url={statisticsUrl}
            onClose={() => {
              this.setState({ showStatistics: false, statisticsUrl: null });
            }}
          />
        )}

        {showMatchDetail && (
          <CMatchDetail
            data={matchDetail}
            activeSport={activeSport}
            match={selectedMatch}
            onClose={() => {
              this.setState({ showMatchDetail: false, matchDetail: [] });
            }}
          />
        )}
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    settings: state.settings,
  };
};

export default connect(msp)(injectIntl(Matches));
